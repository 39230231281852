<template>
  <div class="absolute col-12 h100 p3 border-box" :style="`top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 99; display: grid; place-items: center; background-color: rgba(0,0,0,0.25);`">
    <div class="bg-color-white col-12 h100 max-width-3 lg-p3 p2 border-box" style="max-height: 380px; border-radius: 8px; display: grid; place-items: center">
      <img class="mb2" style="object-fit: contain; max-height: 157px" src="https://static.redseafish.com/wp-content/uploads/2024/11/12140341/storefinder_no_stores_img.png" />
      <p class="lg-h1 h2 color-black helvetica-bold center mb2">
        {{ text }}
      </p>
      <p v-if="subtext != ''" class="helvetica-roman h3 center max-width-2" style="line-height: 1.625rem">{{ subtext }}</p>
      <div v-if="showButton" @click="clickEvent" class="rsf-btn rsf-btn-primary bg-color-1 color-white mx-auto pointer">Ok</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    clickEvent: Function,
    showButton: Boolean,
    text: String,
    subtext: String,
  },
  name: "ErrorPopup",
};
</script>

<style scoped></style>
