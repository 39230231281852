<template>
  <router-view :siteLanguage="siteLanguage"></router-view>
</template>

<script>
export default {
  name: "App",
  props: ["siteLanguage"],
  components: {},
  data() {
    return {};
  },
  created() { },
  mounted() { },
  methods: {},
};
</script>

<style>
html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.wp-site-blocks {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.entry-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.flex-grow {
  flex-grow: 1;
}

.rounded-box {
  border-radius: 8px;
}

.overflow-y-scroll {
  overflow-y: auto;
}

.overflow-x {
  overflow-x: auto;
}

.overflow-x-scroll {
  overflow-x: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.pin-width {
  width: 28px;
}

.pin-width-highlighted {
  width: 38px;
}

@media (min-width: 1920px) {
  .h5.xl-h4 {
    font-size: var(--h4);
  }

  .h7.xl-h6 {
    font-size: var(--h6);
  }

  .pin-width {
    width: 38px;
  }

  .pin-width-highlighted {
    width: 50px;
  }
}

@media(min-width: 1024px) {
  #info-window-horizontal-overflow-container {
    flex-wrap: wrap;
  }

  #info-window-horizontal-overflow-container>a {}
}
</style>
