<template>
  <div ref="draggable" @mousedown="startDrag" @mousemove="doDrag" @touchstart.stop="startDrag" @touchmove.stop="doTouchDrag" @touchend="stopDrag" class="lg-hide bg-color-11" :style="`height: ${draggableHeight}px; transform: translateY(${y}px); border-radius: 16px; position: fixed; top: calc(100% - ${this.draggableOffset}px); width: 100%; ${dragging ? '' : 'transition: 0.4s ease; z-index: 3;'} box-shadow:rgba(0, 0, 0, 0.15) 0px -5px 12px 0px`">
    <div class="col-12 bg-color-11 flex flex-column h100" :style="`border-top-left-radius: 16px; border-top-right-radius: 16px; transition: .3s ease-in-out; border-radius: 16px;`">
      <!-- upper part -->
      <div @mousedown.stop="startDrag" @mousemove.prevent="doDrag" @touchstart.prevent="startDrag, (isTouchingHandle = true)" @touchmove.prevent="doTouchDrag" @touchend.prevent="stopDrag, (isTouchingHandle = false)" style="height: 2rem; place-items: center; display: grid" class="border-box pointer relative no-shrink grid">
        <div class="block mx-auto bg-color-12" style="height: 4px; border-radius: 999px; width: 3rem; flex-shrink: 0; position: absolute; top: 8px; left: 50%; transform: translateX(-50%)"></div>
      </div>

      <!-- Overflow scroll content -->
      <div ref="scrollContent" class="bg-color-11 overflow-y-scroll flex-grow" @scroll.stop="handleScroll">
        <div>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
export default {
  name: "Draggable",
  data() {
    return {
      dragging: false,
      y: 0,
      initialDragOffset: 0,
      expandDraggable: false,
      lastScrollTop: 0, // Track the last scroll position
      scrollVelocity: 0, // Track the velocity of the scroll
      lastTimestamp: 0, // Track the timestamp of the last scroll event
      scrollThreshold: 1.5, // Velocity threshold to determine if expandDraggable should collapse

      mobileInputResizeObserver: null,
      customPropertyObserver: null,
      mobileInputHeight: 0,
      menuHeight: 54,
      draggableOffset: 96,
      draggableHeight: 0,
      isTouchingHandle: false,
    };
  },
  created() {
    EventBus.$on("shrink_draggable", () => this.shrinkDraggable());
    EventBus.$on("highlight_marker", () => this.shrinkDraggable());
  },
  mounted() {
    window.addEventListener("mouseup", this.stopDrag);
    window.addEventListener("touchend", this.stopDrag);

    this.mobileInputResizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        this.mobileInputHeight = entry.target.clientHeight;
        this.calculateDraggableHeight();
      }
    });

    // // Start observing the target element
    this.mobileInputResizeObserver.observe(document.getElementById("mobile-input"));
    this.calculateDraggableHeight();
  },
  beforeDestroy() {
    window.removeEventListener("mouseup", this.stopDrag);
    window.removeEventListener("touchend", this.stopDrag);
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  methods: {
    calculateDraggableHeight() {
      this.draggableHeight = window.innerHeight - this.mobileInputHeight - this.menuHeight;
      if (this.expandDraggable) {
        this.y = -(this.draggableHeight - this.draggableOffset);
      }
    },
    shrinkDraggable() {
      this.expandDraggable = false;
      this.y = 0;
    },
    startDrag(event) {
      if (this.$refs["scrollContent"].scrollTop <= 0 || this.isTouchingHandle) {
        this.dragging = true;
      }

      // Initialize drag offset
      if (!event.touches) return;
      this.initialDragOffset = event.touches[0].clientY - document.documentElement.clientHeight - this.y;

      // Prevent scrolling of the entire page
    },
    stopDrag() {
      this.dragging = false;

      // Logic for snap to top if dragged beyond a certain threshold
      if (this.y > -(this.draggableHeight - this.draggableOffset - 10) && this.expandDraggable) {
        this.y = 0;
        this.expandDraggable = false;
        return;
      }

      if (this.y > -50 && !this.expandDraggable) {
        this.y = 0;
      }

      if (this.y < -50 && !this.expandDraggable) {
        this.expandDraggable = true;
        this.y = -(this.draggableHeight - this.draggableOffset);
      }

      // If scrollContent is at the top, check velocity
      const scrollContent = this.$refs.scrollContent;
      if (scrollContent.scrollTop === 0 && Math.abs(this.scrollVelocity) > this.scrollThreshold) {
        this.expandDraggable = false; // Collapse draggable if velocity is high enough
      }
    },
    doDrag(event) {
      if (!this.expandDraggable || this.y > -(this.draggableHeight - this.draggableOffset)) {
        event.preventDefault();
      }
      if (this.dragging) {
        this.y = event.clientY - document.documentElement.clientHeight - this.initialDragOffset;

        // Prevent dragging above the top
        if (this.y > 0) {
          this.y = 0;
        }

        // Prevent dragging beyond fully expanded state
        if (this.y < -(this.draggableHeight - this.draggableOffset)) {
          this.y = -(this.draggableHeight - this.draggableOffset);
        }
      }
    },
    doTouchDrag(event) {
      if (!this.expandDraggable || this.y > -(this.draggableHeight - this.draggableOffset)) {
        console.log("touch drag prevent");
        event.preventDefault();
        event.stopPropagation();
      }
      if (this.dragging && event.touches.length > 0) {
        this.y = event.touches[0].clientY - document.documentElement.clientHeight - this.initialDragOffset;

        // Prevent dragging above the top
        if (this.y > 0) {
          this.y = 0;
        }

        // Prevent dragging beyond fully expanded state
        if (this.y < -(this.draggableHeight - this.draggableOffset)) {
          this.y = -(this.draggableHeight - this.draggableOffset);
        }
      }
    },
    handleScroll() {
      const scrollContent = this.$refs.scrollContent;
      const currentScrollTop = scrollContent.scrollTop;
      const currentTime = new Date().getTime();

      // Calculate time difference from the last scroll event
      const timeDiff = currentTime - this.lastTimestamp;
      if (timeDiff > 0) {
        // Calculate the scroll difference
        const scrollDiff = this.lastScrollTop - currentScrollTop;

        // Calculate the velocity (pixels per millisecond)
        this.scrollVelocity = scrollDiff / timeDiff;
      }

      // Update last scroll position and timestamp
      this.lastScrollTop = currentScrollTop;
      this.lastTimestamp = currentTime;

      if (this.$refs["scrollContent"].scrollTop <= 0 && this.scrollVelocity > this.scrollThreshold) {
        this.expandDraggable = false;
        this.y = 0;
      }
    },
  },
  watch: {},
};
</script>

<style scoped>
.overflow-y-scroll {
  overflow-y: auto;
}
</style>
